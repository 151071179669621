import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../components/layout';
import { getObject } from '../services/storage'
import {S3ENV} from '../components/envar'
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { Banner } from '../components/banner';
import { handleCmsFile } from '../services/api'
import { isBrowser } from '../services/auth'


export default class guide extends Component {
    constructor(props) {
        super(props);

        // let objContent =  S3ENV.GUIDE + S3ENV.FILE_EXT
        // let envPrefix = S3ENV.ENV
        // const objUri = envPrefix + '-' + objContent;

        this.state = {
            objItems: [],
            activeTab: '1'

        };

        // if(isBrowser()) this.renderObject(objUri)
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
            activeTab: tab
            });
        }
    }

    renderObject = async (objVar) => {

        let objData = await getObject(objVar)
        let objString = objData.post_content
        let objArray = objString.split("||")

        this.setState({ objItems: [...this.state.objItems, ...objArray] });

    }

    async componentDidMount() {

        let result
        var data = {
            stage_file: 'staging-portalguide.json',
            status: "current"
        }
        
        let files = await handleCmsFile(data)
        // console.log(files)
        files.data.forEach(function(item,key){
            if(item.status === 'current'){
                result = item.data      
            }
        })
        
        let objString = result
        let objArray = objString.split("||")

        this.setState({
            objItems: [...this.state.objItems, ...objArray] 
        })
    }


    render() {
        return (
            <Layout>
                <Banner title={`Portal Guide`} />
                <Container
                    style={{
                        padding: '2rem 2rem 1rem',
                        minHeight: '75vh'
                    }}
                    >

                    {this.state.objItems.length === 0 ? null : (
                        <div>
                            <Nav tabs>
                                <NavItem className="navitem">
                                    <NavLink
                                    id="step1"
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                    >
                                    Step 1
                                    </NavLink>
                                </NavItem>
                                <NavItem className="navitem">
                                    <NavLink
                                    id="step2"
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}
                                    >
                                    Step 2
                                    </NavLink>
                                </NavItem>
                                <NavItem className="navitem">
                                    <NavLink
                                    id="step3"
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggle('3'); }}
                                    >
                                    Step 3
                                    </NavLink>
                                </NavItem>
                                <NavItem className="navitem">
                                    <NavLink
                                    id="step4"
                                    className={classnames({ active: this.state.activeTab === '4' })}
                                    onClick={() => { this.toggle('4'); }}
                                    >
                                    Step 4
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <div dangerouslySetInnerHTML={ {__html: this.state.objItems[0]} } />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <div dangerouslySetInnerHTML={ {__html: this.state.objItems[1]} } />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col sm="12">
                                            <div dangerouslySetInnerHTML={ {__html: this.state.objItems[2]} } />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Row>
                                        <Col sm="12">
                                            <div dangerouslySetInnerHTML={ {__html: this.state.objItems[3]} } />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    )}
                    


                </Container>
            </Layout>
        )
    }
}
